import React, { useEffect } from "react";
import { NextPage } from "next";
import { withPageAuthRequired } from "@auth0/nextjs-auth0/client";
import { useSelector } from "react-redux";
import { useCreateReport } from "source/hooks/matrix/useCreateReport";
import { getCurrentOrg, getOrgsLoaded } from "source/redux/organization";
import logger from "source/utils/logger";
import { Loading } from "source/components/shared/Loading";
import { useGlobalNavigator } from "source/hooks/useSetRouter";

const NewMatrixPage: NextPage = () => {
  const { createReport } = useCreateReport();
  const orgId = useSelector(getCurrentOrg)?.id;
  const { goToHome, goToMatrix } = useGlobalNavigator();
  const orgsLoaded = useSelector(getOrgsLoaded);

  useEffect(() => {
    if (!orgsLoaded) return;
    if (!orgId) {
      logger.warn("No org_id present, redirecting to homepage");
      goToHome();
    } else {
      createReport({
        orgId,
        replaceReport: true,
      })
        .then((data) => {
          const matrixId = data.id;
          logger.info("Created matrix, routing to new matrix.", {
            matrix_id: matrixId,
          });
          goToMatrix(matrixId);
        })
        .catch((e) => {
          logger.error("Unable to create Matrix", { error: e });
          goToHome();
        });
    }
  }, [orgsLoaded]);

  return <Loading />;
};

// fast/cached SSR page
// use this for public pages so we can cache ssr
export default withPageAuthRequired(NewMatrixPage, { returnTo: "/matrix/new" });
